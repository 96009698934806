require('./bootstrap');
require('fslightbox');

var miwuki = function() {

    var init = function () {
        let toggleMenuBtn = document.getElementById('toggle-menu');
        if(toggleMenuBtn) {
            toggleMenuBtn.addEventListener('click', function() {
                toggleMenu(this);
            });
        }
        let togglePerfilBtn = document.getElementById('toggle-perfil');
        if(togglePerfilBtn) {
            togglePerfilBtn.addEventListener('click', function() {
                toggleMenuPerfil(this);
            });
        }
    }

    var toggleMenu = function (menu) {
        menu.dataset.state = (menu.dataset.state === 'close')?'open':'close'
        let state = (menu.dataset.state === 'open')?'display: block !important':''
        document.getElementById('nav-menu').setAttribute('style', state)
    }

    var toggleMenuPerfil = function (menu) {
        menu.dataset.state = (menu.dataset.state === 'close')?'open':'close'
        let state = (menu.dataset.state === 'open')?'display: block !important':''
        document.getElementById('nav-perfil').setAttribute('style', state)
    }

    return {
        init: init,
    };
}();

document.addEventListener("DOMContentLoaded", function(event) {
    miwuki.init();
});
